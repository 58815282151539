import CardMessage from '../../../../../components/CardMessage'
import iconCheck from '../../../../../assets/images/icone_check_branco.svg'

export default {
  name: 'IdentifiedSuccess',
  props: {
    code: {
      type: String,
      required: true,
    },
    isCollaborator: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      iconCheck,
    }
  },

  components: {
    CardMessage,
  },
}
